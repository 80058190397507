import auth0 from "auth0-js"
import { navigate } from "gatsby"
import axios from "axios"

const isBrowser = typeof window !== "undefined"

console.log("DOMAINS", process.env.AUTH0_DOMAIN)

const auth = isBrowser
  ? new auth0.WebAuth({
      domain: process.env.AUTH0_DOMAIN || "myprivatefund.auth0.com",
      clientID: process.env.AUTH0_CLIENTID || "I2XpfROqa66gndgPqoizoC8tNqBMNW0L",
      redirectUri: process.env.AUTH0_CALLBACK || "https://fundmyprivatecampaign.com/callback",
      responseType: "token id_token",
      scope: "openid profile email app_metadata",
      audience: 'http://localhost:9000'
    })
  : {}


  const tokens = {
    accessToken: false,
    idToken: false,
    expiresAt: false,
  }
  
  let user = {}
  
  export const isAuthenticated = () => {
    if (!isBrowser) {
      return;
    }
  
    return localStorage.getItem("isLoggedIn") === "true"
  }
  
  export const login = () => {
    if (!isBrowser) {
      return
    }
    //auth.authorize({ scope: "openid profile email app_metadata" })
    navigate("/login")
  }
  
  const setSession = (cb) => (err, authResult) => {
    if (err) {
      console.log(err)
      //logout()
      cb()
      return
    }

    console.log('authResult', authResult)

    if (!authResult) logout()
  
    if (authResult && authResult.accessToken && authResult.idToken) {
      let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
      tokens.accessToken = authResult.accessToken
      tokens.idToken = authResult.idToken
      tokens.expiresAt = expiresAt
      user = authResult.idTokenPayload
      localStorage.setItem("isLoggedIn", true)
      cb()
    }
  }
  
  export const handleAuthentication = () => {
    if (!isBrowser) {
      return;
    }
  
    auth.parseHash(setSession(async function () {
      if (typeof window == "object" && window.localStorage.stripe_session_id) {
        console.log("LOGGED IN", localStorage.stripe_session_id, user)

        await axios.post(
          `/.netlify/functions/post-link-stripe`,
          { session_id: localStorage.stripe_session_id, _id: user.sub.replace("auth0|", "") },
          { headers: { Authorization: `Bearer ${getTokenSilently()}`}}
        )
      }
  
      navigate("/campaigns/247256867411067410")
    }))

    
  }
  
  export const getProfile = () => {
    return user
  }

  export const silentAuth = callback => {
    if (!isAuthenticated()) return callback()
    console.log('check auht')
    auth.checkSession({}, setSession(callback))
  }

  export const logout = () => {
    localStorage.setItem("isLoggedIn", false)
    console.log("logout", localStorage)
    window.location = `https://myprivatefund.auth0.com/v2/logout?returnTo=${window.location.protocol + "//" + window.location.host + "/campaigns/247256867411067410"}`
    // auth.logout({
    //     returnTo: `${window.location.protocol + "//" + window.location.host + "/"}`
    // })
  }

  export const getTokenSilently = () => tokens.accessToken

  export const customLogin = async (email, password) => {
    if (!isBrowser) {
      return
    }

    return new Promise((resolve, reject) => {
      auth.login({
        realm: 'Username-Password-Authentication',
        username: email,
        password: password,
      }, function (err) {
        if (err) {
          console.log("ERROR IN LOGIN: ", err)
          reject(err.description)
          return
        }
        resolve()
      });
    })

  }
 
  