// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-admin-js": () => import("/opt/build/repo/src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-callback-js": () => import("/opt/build/repo/src/pages/callback.js" /* webpackChunkName: "component---src-pages-callback-js" */),
  "component---src-pages-campaigns-247256867411067410-js": () => import("/opt/build/repo/src/pages/campaigns/247256867411067410.js" /* webpackChunkName: "component---src-pages-campaigns-247256867411067410-js" */),
  "component---src-pages-campaigns-index-js": () => import("/opt/build/repo/src/pages/campaigns/index.js" /* webpackChunkName: "component---src-pages-campaigns-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-index-js": () => import("/opt/build/repo/src/pages/login/index.js" /* webpackChunkName: "component---src-pages-login-index-js" */)
}

